import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import SocialMediaFixed from '../components/socialmedia-fixed';
import Packages from '../components/packages';

const PackagesPage = () => {
    return (
        <>
            <Helmet>
                <title>Packages at Corbett Aroma Park - Exclusive Offers & Deals</title>
                <meta name="description" content="Discover exclusive holiday packages at Corbett Aroma Park Resort. Enjoy special deals for family vacations, romantic getaways, adventure trips, and more near Jim Corbett National Park." />
                <meta name="keywords" content="Corbett Aroma Park packages, holiday deals Ramnagar Dhikuli, Jim Corbett National Park packages, family vacation deals Corbett, romantic getaway offers, adventure packages Corbett, resort special deals" />
                <link rel="canonical" href="https://www.aromaparkcorbett.com/packages" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Resort",
                        "name": "Packages at Corbett Aroma Park - Exclusive Offers & Deals",
                        "url": "https://www.aromaparkcorbett.com/packages",
                        "telephone": "+91-9958866101",
                    })}
                </script>
            </Helmet>

            <TopBanner />
            <Navbar />
            <Packages />
            <Footer />
            <SocialMediaFixed />
        </>
    );
};

export default PackagesPage;
