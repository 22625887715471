import React from 'react'
import JeepSafari from "../assets/jungle-safari/jeep_safari.jpg"
import CanterSafari from "../assets/jungle-safari/canter_safari.jpg"
import '../css/jungle-safari.css'

const JungleSafari = () => {
    return (
        <div className='jungle-safari-section'>
            <h1>Jungle Safari - Jim Cobett National Park</h1>
            <div className="jungle-safari-container">
                <div className="jungle-safari-left">
                    <img src={JeepSafari} alt="" />
                    <div className="jungle-safari-content">
                        <h2>Jeep Safari</h2>
                        <p>The jeep safari allows a maximum of 6 people per jeep, with foreigners required to pay slightly more than Indian nationals for this incredible tour of the scenic surroundings.</p>
                    </div>
                </div>
                <div className="jungle-safari-right">
                    <img src={CanterSafari} alt="" />
                    <div className="jungle-safari-content">
                        <h2>Canter Safari</h2>
                        <p>The canter safari whose important features are: It is relatively cheaper than the jeep safari Each canter can take a maximum of 16 people. This safari is only available at the Dhikala zone.</p>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default JungleSafari
