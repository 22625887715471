import React from 'react'
import { Helmet } from 'react-helmet-async'
import Navbar from '../components/navbar'
import TopBanner from '../components/top-banner'
import Footer from '../components/footer'
import GardenViewRoomComp from '../components/garden-view-room'
import SocialMediaFixed from '../components/socialmedia-fixed'


const GardenViewRoom = () => {

    return (
        <>
            <Helmet>
                <title>Garden View Room | Corbett Aroma Park Resort, Ramnagar Dhikuli</title>
                <meta name="description" content="Book a Garden View Room at Corbett Aroma Park Resort, Ramnagar Dhikuli. Enjoy a peaceful stay with scenic garden views, modern amenities, and a tranquil atmosphere for a perfect getaway." />
                <meta name="keywords" content="Garden View Room Corbett Aroma Park, Corbett resort garden view room, Jim Corbett rooms with garden view, peaceful garden view rooms Ramnagar Dhikuli, resort rooms with garden view" />
                <link rel="canonical" href="https://www.aromaparkcorbett.com/room/garden-view-room" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Room",
                        "name": "Garden View Room",
                        "url": "https://www.aromaparkcorbett.com/rooms/garden-view-room",
                        "telephone": "+91-9958866101",
                        "roomType": "Garden View Room",

                    })}
                </script>
            </Helmet>



            {<TopBanner />}
            {<Navbar />}
            {<GardenViewRoomComp />}
            {<Footer />}
            {<SocialMediaFixed />}
        </>
    )
}

export default GardenViewRoom
