import React from 'react'
import '../css/how-to-reach.css'

const HowToReach = () => {
    return (
        <div className='how-to-reach-section'>
            <h1>Corbett Aroma Park - Distances & How to Reach</h1>
            <br />
            <p>
                Resort Location
            </p>
            <br />
            <ul>
                <li>7 Km from Ram Nagar Railway station</li>
                <li>60 km from Kathgodam railway station</li>
                <li>150 Km from Pant Nagar Airport</li>
                <li>245 Km From Delhi ( By Car)</li>
            </ul>
            <br />
            <p>Nearby locations and learn how to reach</p>
            <br />
            <ul>
                <li>290 Km From Delhi ( By Car)</li>
                <li>90 Km from Pant Nagar Airport</li>
                <li>9 Km from Ram Nagar Railway station</li>
                <li>Dhikala Safari Gate 20 km.</li>
                <li>Bijrani Safari Gate :-9 Km</li>
                <li>Dhela Safari Gate :-20 km</li>
                <li>Jhirna Safari Gate :-20 km</li>
                <li>Durgadevi Safari Gate :- 30 km</li>
                <li>Sitavani Safari Gate:- 15 km</li>
            </ul>
        </div>
    )
}

export default HowToReach
