import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import SocialMediaFixed from '../components/socialmedia-fixed';
import PlacesToVisit from '../components/places-to-visit';

const PlacesToVisitPage = () => {
    return (
        <>
            <Helmet>
                <title>Places to Visit Near Corbett Aroma Park - Explore Attractions</title>
                <meta name="description" content="Explore must-visit attractions near Corbett Aroma Park Resort in Ramnagar Dhikuli. Discover Corbett Fall, Garjiya Temple, Hanuman Dham, Corbett Museum, Ramnagar, and thrilling Jungle Safaris." />
                <meta name="keywords" content="places to visit near Corbett Aroma Park, Corbett Fall, Garjiya Temple, Hanuman Dham, Corbett Museum, Ramnagar attractions, Jim Corbett Jungle Safari, tourist spots Ramnagar Dhikuli" />
                <meta name="robots" content="index, follow" />
                <link rel="canonical" href="https://www.aromaparkcorbett.com/places-to-visit" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Resort",
                        "name": "Places to Visit Near Corbett Aroma Park - Explore Attractions",
                        "url": "https://www.aromaparkcorbett.com/places-to-visit",
                        "telephone": "+91-9958866101",
                    })}
                </script>
            </Helmet>


            <TopBanner />
            <Navbar />
            <PlacesToVisit />
            <Footer />
            <SocialMediaFixed />
        </>
    );
};

export default PlacesToVisitPage;
