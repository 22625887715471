import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import TopBanner from '../components/top-banner';
import Footer from '../components/footer';
import OurGallery from '../components/our-gallery';
import SocialMediaFixed from '../components/socialmedia-fixed';

const GalleryPage = () => {
    return (
        <>
            <Helmet>
                <title>Gallery | Corbett Aroma Park Resort, Ramnagar Dhikuli</title>
                <meta name="description" content="View stunning photos of Corbett Aroma Park Resort in Ramnagar Dhikuli. Explore our luxurious rooms, serene surroundings, and memorable moments captured at our resort." />
                <meta name="keywords" content="Corbett Aroma Park gallery, resort photos Ramnagar, luxury resort images, Jim Corbett resort pictures, rooms and amenities gallery, Ramnagar Dhikuli resort visuals" />
                <link rel="canonical" href="https://www.aromaparkcorbett.com/gallery" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Resort",
                        "name": "Gallery | Corbett Aroma Park Resort, Ramnagar Dhikuli",
                        "url": "https://www.aromaparkcorbett.com/gallery",
                        "telephone": "+91-9958866101",
                    })}
                </script>
            </Helmet>




            <TopBanner />
            <Navbar />
            <OurGallery />
            <Footer />
            <SocialMediaFixed />
        </>
    );
}

export default GalleryPage;
