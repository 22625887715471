import React from 'react';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/navbar';
import Footer from '../components/footer';
import TopBanner from '../components/top-banner';
import SocialMediaFixed from '../components/socialmedia-fixed';
import HeroSection from '../components/hero-section';
import IntroductionSection from '../components/introduction-section';
import OurResortValue from '../components/our-resort-value';
import GuestReviews from '../components/guest-reviews';
import ExploreRoomsSection from '../components/explore-rooms-section';
import JimCorbettNationalPark from '../components/jim-corbett-national-park';
import HowToReach from '../components/how-to-reach';
import JungleSafari from '../components/jungle-safari';


const HomePage = () => {
    return (
        <>
            <Helmet>
                <title>Corbett Aroma Park Resort | Best Resort in Ramnagar Dhikuli near Jim Corbett National Park</title>
                <meta name="description" content="Experience tranquility at Corbett Aroma Park Resort, located in the serene Ramnagar Dhikuli near Jim Corbett National Park. Enjoy luxury accommodations, lush surroundings, and thrilling wildlife experiences." />
                <meta name="keywords" content="Corbett Aroma Park Resort, resort in Ramnagar Dhikuli, resorts near Jim Corbett National Park, luxury resort Corbett, best resort Ramnagar, Jim Corbett accommodations, wildlife resort, nature resort in Dhikuli, Resort in Dhikuli Ramnagar, Resort in Dhikuli, Resort in Jim Corbett Dhikuli, Resorts Dhikuli Ramnagar, Corbett resort in Dhikuli village, Resorts in Jim Corbett Dhikuli, Hotel in Dhikuli Ramnagar, Resort in Ramnagar Corbett, Dhikuli Ramnagar resorts, Dhikuli resort, Resort in Jim Corbett Nainital, Jim Corbett resort in Ramnagar, Resorts in Dhikuli Corbett, Ramnagar Corbett resort, 4-star resort in Ramnagar, Best resort in Dhikuli" />
                <link rel="canonical" href="https://www.aromaparkcorbett.com/" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Resort",
                        "name": "Corbett Aroma Park Resort | Best Resort in Ramnagar Dhikuli near Jim Corbett National Park",
                        "url": "https://www.aromaparkcorbett.com/",
                        "telephone": "+91-9958866101"
                    })}
                </script>
            </Helmet>


            <TopBanner />
            <Navbar />
            <HeroSection />
            <IntroductionSection />
            <OurResortValue />
            <ExploreRoomsSection />
            <JungleSafari />
            <JimCorbettNationalPark />
            <HowToReach />
            <GuestReviews />
            <Footer />
            <SocialMediaFixed />
        </>
    );
};

export default HomePage;
