import React from 'react'
import { Helmet } from 'react-helmet-async'
import Navbar from '../components/navbar'
import TopBanner from '../components/top-banner'
import Footer from '../components/footer'
import ExploreRoomsSection from '../components/explore-rooms-section'
import SocialMediaFixed from '../components/socialmedia-fixed'

const RoomsPage = () => {
    return (
        <>
            <Helmet>
                <title>Rooms & Accommodations | Corbett Aroma Park Resort, Ramnagar Dhikuli</title>
                <meta name="description" content="Explore our luxurious rooms at Corbett Aroma Park Resort, Ramnagar Dhikuli. Choose from Deluxe, Super Deluxe, Premium, and Suite options designed for comfort and relaxation near Jim Corbett National Park." />
                <meta name="keywords" content="Corbett Aroma Park rooms, accommodations in Ramnagar Dhikuli, Deluxe Room Corbett, Super Deluxe Room Ramnagar, Premium Room Corbett, Suite Room Jim Corbett, luxury stays Ramnagar, Jim Corbett National Park resort rooms" />
                <link rel="canonical" href="https://www.aromaparkcorbett.com/rooms" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Resort",
                        "name": "Rooms & Accommodations | Corbett Aroma Park Resort, Ramnagar Dhikuli",
                        "url": "https://www.aromaparkcorbett.com/rooms",
                        "telephone": "+91-9958866101",
                        "hotelRoom": [
                            {
                                "@type": "HotelRoom",
                                "name": "Deluxe Room"
                            },
                            {
                                "@type": "HotelRoom",
                                "name": "Premium Room"
                            },
                            {
                                "@type": "HotelRoom",
                                "name": "Garden View Room"
                            }
                        ]
                    })}
                </script>
            </Helmet>



            {<TopBanner />}
            {<Navbar />}
            {<ExploreRoomsSection />}
            {<Footer />}
            {<SocialMediaFixed />}
        </>
    )
}

export default RoomsPage
