import React from 'react';
import { Helmet } from 'react-helmet-async';
import TopBanner from '../components/top-banner';
import Navbar from '../components/navbar';
import ContactUs from '../components/contact-us';
import Footer from '../components/footer';
import SocialMediaFixed from '../components/socialmedia-fixed';

const ContactPage = () => {
    return (
        <>
            <Helmet>
                <title>Contact Us | Corbett Aroma Park Resort, Ramnagar Dhikuli</title>
                <meta name="description" content="Get in touch with Corbett Aroma Park Resort for bookings, inquiries, and assistance. Located in Ramnagar Dhikuli, we are here to provide you with a luxurious stay in Jim Corbett." />
                <meta name="keywords" content="Contact Corbett Aroma Park, Ramnagar Dhikuli, resort inquiries, booking assistance, Corbett Aroma Park customer service, Jim Corbett resort contact, resorts in Ramnagar" />
                <link rel="canonical" href="https://www.aromaparkcorbett.com/contact" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Resort",
                        "name": "Contact Us | Corbett Aroma Park Resort, Ramnagar Dhikuli",
                        "url": "https://www.aromaparkcorbett.com/contact",
                        "telephone": "+91-9958866101"
                    })}
                </script>
            </Helmet>


            <TopBanner />
            <Navbar />
            <ContactUs />
            <Footer />
            <SocialMediaFixed />
        </>
    );
}

export default ContactPage;
