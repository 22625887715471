import React from 'react'
// import JimCorbett from "../assets/jim_corbett.jpg"
import '../css/jim-corbett-national-park.css'

const JimCorbettNationalPark = () => {
  return (
    <div className='jim-corbett-national-park-section'>
      <div className="jim-corbett-national-park-left">
      </div>
      <div className="jim-corbett-national-park-right">
        <h2>Jim Corbett National Park: India First Wildlife Reserve</h2>
        <p>Jim Corbett National Park is the most exciting ecotourism destination in India encapsulating 488 different species of plants and a diverse variety of fauna that includes 580 bird species and 25 reptile species. Located in Nainital district of Uttarakhand, Jim Corbett National Park is the oldest national park in India named after great hunter turned conservationist Jim Corbett. Jim Corbett National park was established in 1936 as a part of Indian wildlife protection initiative to ensure the protection of endangered Tigers in India, especially the Bengal tigers. Jim Corbett Park is been the first wildlife reserve of India extended over an area of more than 520 sq km in the Himalayan foothills.

        Covering more than 520 sq km area of hills, river belts, marshy depressions, grass lands and the large lake, Jim Corbett National Park is a heaven for the adventure seekers and wildlife adventure lovers from across the world. Dense moist deciduous forest mainly comprises of Sal, haldu, pipal, Rohini and mango trees cover almost 73 per cent of Jim Corbett National Park. Winter nights in Corbett national park are generally cold but the days are bright and quite sunny. Jim Corbett Park receives rains from July to September.</p>
      </div>
    </div>
  )
}

export default JimCorbettNationalPark
