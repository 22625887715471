import React from "react";
import { Helmet } from "react-helmet-async";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import TopBanner from "../components/top-banner";
import SocialMediaFixed from "../components/socialmedia-fixed";
import OurResortValue from "../components/our-resort-value";
import IntroductionSection from "../components/introduction-section";
import JimCorbettNationalPark from '../components/jim-corbett-national-park';
import HowToReach from '../components/how-to-reach';
import JungleSafari from '../components/jungle-safari';

const AboutPage = () => {
    return (
        <>
            <Helmet>
                <title>About Us | Corbett Aroma Park Resort - Luxury Stay in Ramnagar Dhikuli</title>
                <meta name="description" content="Discover the story of Corbett Aroma Park Resort in Ramnagar Dhikuli, a serene retreat offering luxurious accommodations, natural beauty, and a gateway to Jim Corbett National Park." />
                <meta name="keywords" content="About Corbett Aroma Park Resort, resort story Ramnagar Dhikuli, luxury stays near Jim Corbett, nature retreat Corbett, resort history, Jim Corbett hospitality" />
                <link rel="canonical" href="https://www.aromaparkcorbett.com/about" />

                {/* JSON-LD structured data */}
                <script type="application/ld+json">
                    {JSON.stringify({
                        "@context": "https://schema.org",
                        "@type": "Resort",
                        "name": "About Us | Corbett Aroma Park Resort - Luxury Stay in Ramnagar Dhikuli",
                        "url": "https://www.aromaparkcorbett.com/about",
                        "telephone": "+91-9958866101"
                    })}
                </script>
            </Helmet>



            <TopBanner />
            <Navbar />
            <IntroductionSection />
            <OurResortValue />
            <JungleSafari />
            <JimCorbettNationalPark />
            <HowToReach />
            <Footer />
            <SocialMediaFixed />
        </>
    );
};

export default AboutPage;
