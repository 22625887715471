import React from "react";
import "../css/places-to-visit.css";

const PlacesToVisit = () => {
  return (
    <section className="places-to-visit-section">
      <h1 className="swipe">Places To Visit</h1>
      <br />
      <div className="places-to-visit-container">
        <div className="places-to-visit-card places-to-visit-card1 swipe">
          <div className="places-to-visit-card-content">
            <h2>CORBETT FALL</h2>
            <p>
              Corbett Water Falls is situated 25 Km. from Ramnagar, is very
              famous and visited. Surrounded by dense jungles, the Corbett Falls
              can be reached via narrow road of nearly 1.7 km. from the main
              road. Feel refreshed at the nature at its best here.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card2 swipe">
          <div className="places-to-visit-card-content">
            <h2>GARJIYA TEMPLE</h2>
            <p>
              Within close proximity to the Corbett Jungle Park is the quaint
              temple dedicated to the Goddess Parvati, known as Garjia by
              locals. The devotees visiting the temple offer coconut, incense,
              sweets, vermilion, lamp, clothes, and chunnari along with devotion
              at the the temple.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card3 swipe">
          <div className="places-to-visit-card-content">
            <h2>HANUMAN DHAM</h2>
            <p>
              Situated 1Km from Winsome Resort and Spa, the Hanuman Dham is a
              famous and must-visit in the list of tourists. A big idol of Lord
              Hanuman marks the temple. Old and simple architecture and faith
              live in the temple. Locals along with tourists offer special
              prayers on Tuesdays and Saturdays.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card4 swipe">
          <div className="places-to-visit-card-content">
            <h2>CORBETT MUSEUM</h2>
            <p>
              Jim Corbett National Park got its name from the activist and
              environmentalist Jim Corbett. The Corbett museum is a heritage
              property made out of the bungalow of the renowned activist. The
              Corbett museum is do my english homework situated at Kaladungi.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card5 swipe">
          <div className="places-to-visit-card-content">
            <h2>RAMNAGAR</h2>
            <p>
              Ramnagar is a small town situated in the ‘Kumaon’ region of
              Uttarakhand and is located in the district of Nainital
              (Uttarakhand). It is also the main administrative center for Jim
              Corbett National Park and Project Tiger.
            </p>
          </div>
        </div>
        <div className="places-to-visit-card places-to-visit-card6 swipe">
          <div className="places-to-visit-card-content">
            <h2>JUNGLE SAFARI</h2>
            <p>
              Dhikala zone keeps celebrity status among all the Corbett National
              Park tourist zones. It is the most sought-after zone for the day
              safari as well as for the night stay in the Dhikala forest rest
              house.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default PlacesToVisit;
