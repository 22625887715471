import React from "react";
import "../css/introduction-section.css";
import IntroductionImg from "../assets/hero.jpg";

const IntroductionSection = () => {
  return (
    <section className="introduction-section">
      <div className="introduction-section-right swipe">
        <h1 className="after">
          Welcome To <br />
          <h1>Corbett Aroma Park</h1>
        </h1>
        <p>
        Immerse yourself in luxury: Experience our spacious and well-appointed rooms, designed to provide utmost comfort during your stay. Enjoy modern amenities and breathtaking views of the surrounding landscapes.

        Explore the wilderness: Corbett National Park is just a stone's throw away, offering an opportunity to witness the majestic tigers and diverse wildlife in their natural habitat. Embark on thrilling safari adventures and create memories that last a lifetime.
        </p>
      </div>
      <div className="introduction-section-left swipe">
        <img
          src={IntroductionImg}
          alt="The Pearl 21 Villa Image"
          loading="eager"
          width="100%"
          height="auto"
        />
      </div>
    </section>
  );
};

export default IntroductionSection;
